import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { ProofsService } from '../../../services/proofs.service'
import { DocumentService } from '../../../services/document.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { InvoiceService } from '../../../services/invoice.service'
import { DomSanitizer } from '@angular/platform-browser'
import { ConfirmWithTextDialogComponent } from '../confirm-with-text-dialog/confirm-with-text-dialog.component'
import * as currency from 'currency.js'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { AuthService } from '../../../services/auth.service'
import { CustomerDetailGeneralModel } from '../../../models/customer-patient/customer-detail-general.model'
import { SendInvoiceReminderDialogComponent } from '../send-invoice-reminder-dialog/send-invoice-reminder-dialog.component'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-invoice-view-dialog',
  templateUrl: './invoice-view-dialog.component.html',
})
export class InvoiceViewDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  public submittedDelete = false
  public submitted = false

  private currencyOptions = {
    // Cent
    decimal: ',',
    // Tausender
    separator: '.',
    symbol: '€',
    pattern: `# !`,
  }

  public loading = true

  public data: any = {}
  public patient: PatientModel = new PatientModel()
  public customerDetail: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()

  public pdfSrc: any = ''
  public pdfSrcSafe: any = ''
  private eventBusSubscription: Subscription = new Subscription()

  public rotating = false

  public type: any = []
  public comment = ''

  public isPkv = false
  public isSelbstzahler = false
  public hasMedia = false

  public typeOptions = [
    { label: 'Sonstiges', value: 'OTHER' },
    { label: 'Fehlerhafter Leistungsnachweis', value: 'PROOF_ISSUE' },
    { label: 'Fehlerhaftes Budget', value: 'BUDGET_ISSUE' },
    { label: 'Fehlerhafte Daten Kunde', value: 'CUSTOMER_DATA_ISSUE' },
    { label: 'Änderung Termin', value: 'APPOINTMENT_CHANGE' },
  ]

  public stampSaveSubmitted = false
  public withDateStamp = false
  private positions = {
    stamp: {
      x: 0,
      y: 0,
    },
    date: {
      x: 0,
      y: 0,
    },
  }
  public costUnitHasNoContactPersons = false
  public receiversData: any = {}
  public patientReceiverOptions: any[] = []
  public costUnitReceiverOptions: any[] = []
  public costUnitEmailReceiverOptions: any[] = []
  public addressReceiverOptions: any[] = []
  public ccOptions: any[] = []
  public formOfAddress: any = []

  public partialPaymentEditId: any = null
  public partialPayment = ''

  public nextReminderType: null | number = 1
  public allReminders: any = []

  public invoiceValues = {
    invoice_receiver: '',
    individual_email: '',
    individual_address: '',
    individual_form_of_address: '',
    individual_first_name: '',
    individual_last_name: '',
    individual_street_house_number: '',
    individual_zipcode: '',
    individual_city: '',
    accounting_type: '',
    patient_receiver: '',
    cost_unit_receiver: '',
    cost_unit_email_receiver: '',
    accounting_receiver: '',
    payment_type: '',
    send_type: '',
    receiver: null as any,
    cc_receivers: [],
  }

  constructor(
    public authService: AuthService,
    private ref: DynamicDialogRef,
    public proofService: ProofsService,
    public invoiceService: InvoiceService,
    public config: DynamicDialogConfig,
    private patientService: PatientService,
    private dialogService: DialogService,
    public documentService: DocumentService,
    private sanitizer: DomSanitizer,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  public ngOnInit(): void {
    this.data = this.config.data.invoice

    if (this.data.type === 'KM') {
      this.patient = this.data.invoiceable
    } else if (this.data.type === 'CONSULTING') {
      this.patient = this.data.invoiceable.patient
    } else if (this.data.type === 'APPOINTMENT') {
      this.patient = this.data.invoiceable.patient
    }

    this.pdfSrc = this.documentService.getProofImageDocumentDownloadLink(
      this.data.media[0].uuid
    )

    this.pdfSrcSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc)

    this.loadReminders()
    this.loadPatientData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.InvoiceReminderSent:
            this.loadReminders()
        }
      }
    )
  }

  public editPayment(payment: any): void {
    this.partialPaymentEditId = payment.id
    this.partialPayment = payment.payment_euro
  }

  public markInvoiceAsCompleted(): void {
    if (!window.confirm('Rechnung wirklich abschließen?')) {
      return
    }

    this.submitted = true

    this.invoiceService.markInvoiceAsCompleted(this.data.id).subscribe(
      (invoice: any) => {
        this.data = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success(
          'Rechnung abgeschlossen',
          'Die Rechnung wurde erfolgreich abgeschlossen'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public resetCompletedStatus(): void {
    if (!window.confirm('Rechnung wirklich zurücksetzen?')) {
      return
    }

    this.submitted = true

    this.invoiceService.resetCompletedStatus(this.data.id).subscribe(
      (invoice: any) => {
        this.data = invoice

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        this.toastService.success(
          'Rechnung zurückgesetzt',
          'Die Rechnung wurde erfolgreich zurückgesetzt'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public addPayment(): void {
    this.submitted = true

    // Formatiert die Zahl in einen €-String
    const partialPaymentFormatted = currency(
      this.partialPayment,
      this.currencyOptions
    ).format()

    const subscription = this.partialPaymentEditId
      ? this.invoiceService.updatePayment(
          this.partialPaymentEditId,
          partialPaymentFormatted
        )
      : this.invoiceService.addPayment(this.data.id, partialPaymentFormatted)

    subscription.subscribe(
      (invoice: any) => {
        this.data.part_payments = invoice.part_payments
        this.data.part_payments_sum = invoice.part_payments_sum
        this.data.part_payments_sum_euro_formatted =
          invoice.part_payments_sum_euro_formatted

        this.partialPayment = ''
        this.partialPaymentEditId = null

        this.submitted = false
        this.eventbus.emit(GlobalEvent.InvoiceChanged)
        // this.ref.close()
        this.toastService.success('Teilzahlung hinzugefügt')
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public stornoInvoice(): void {
    const ref = this.dialogService.open(ConfirmWithTextDialogComponent, {
      header: 'Rechnung wirklich stornieren?',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        invoice: this.data,
        isRequired: true,
      },
    })

    ref.onClose.subscribe((values: any) => {
      if (values) {
        this.submittedDelete = true
        this.submitted = true

        this.invoiceService
          .stornoInvoice(
            this.data.id,
            values.storno_comment,
            values.with_new_draft
          )
          .subscribe(
            (response: any) => {
              this.eventbus.emit(GlobalEvent.InvoiceChanged)
              this.ref.close()

              this.toastService.success(
                'Rechnung storniert',
                'Die Rechnung wurde erfolgreich storniert'
              )
            },
            () => {
              this.toastService.error(
                'Etwas ist schiefgelaufen...',
                'Bitte wenden Sie sich an den Support'
              )

              this.submittedDelete = false
            }
          )
      }
    })
  }

  public sendInvoiceReminder(): void {
    this.dialogService.open(SendInvoiceReminderDialogComponent, {
      header: `${this.nextReminderType}. Mahnung erstellen`,
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        invoice: this.data,
        customerDetail: this.customerDetail,
      },
    })
  }

  private loadPatientData(): void {
    this.patientService.load(this.patient.id).subscribe((response: any) => {
      this.customerDetail = response

      this.loading = false
    })
  }

  private loadReminders(): void {
    this.invoiceService
      .loadReminders(this.data.id)
      .subscribe((response: any) => {
        const lastReminder = response.last_reminder
        this.allReminders = response.all_reminders

        // Wir prüfen, ob es bereits Mahnungen gibt.
        if (lastReminder) {
          // Falls bereits die erste Mahnung erstellt wurde, müssen
          // wir die zweite Mahnung erstellen können.
          if (lastReminder.type === 1) {
            this.nextReminderType = 2
          } else if (lastReminder.type === 2) {
            // Falls aber bereits die zweite Mahnung schon erstellt wurde,
            // können wir keine Mahnungen mehr erstellen.
            this.nextReminderType = null
          }
        }
      })
  }
}
