<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="data.length > 0">
    <span> </span>

    <div class="content-header-buttons">
      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="content-body auto-height" *ngIf="data.length === 0">
    <span class="content-no-message">Keine KM-Rechnungen vorhanden</span>
  </div>
  <div class="content-body without-padding auto-height" *ngIf="data.length > 0">
    <p-table
      #dt
      [value]="data"
      [globalFilterFields]="['invoice_number_complete']"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 160px" pSortableColumn="status">
            Status <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_type">
            Typ <p-sortIcon field="invoice_type"></p-sortIcon>
          </th>
          <th pSortableColumn="invoice_number">
            Rechnungsnummer
            <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th>Anzahl fahrten</th>
          <th pSortableColumn="trips_mileage_sum">
            Anzahl Kilometer
            <p-sortIcon field="trips_mileage_sum"></p-sortIcon>
          </th>
          <th pSortableColumn="total_price">
            Gesamtkosten
            <p-sortIcon field="total_price"></p-sortIcon>
          </th>
          <th>Bezahlt</th>
          <th pSortableColumn="general_date">
            Datum
            <p-sortIcon field="general_date"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td (click)="openInvoiceDialog(item)">
            <span *ngIf="!item.is_credit && !item.is_storno" class="p-tag p-tag-rounded p-tag-{{ item.status }}">{{
                item.status_text
              }}</span>
          </td>
          <td (click)="openInvoiceDialog(item)">{{ item.invoice_type }}</td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.invoice_number_complete }}
          </td>

          <td (click)="openInvoiceDialog(item)" *ngIf="!item.is_storno">
            {{ item.trips.length }}
            {{ item.trips.length === 1 ? "Fahrt" : "Fahrten" }}
          </td>
          <td (click)="openInvoiceDialog(item)" *ngIf="item.is_storno">-</td>

          <td (click)="openInvoiceDialog(item)" *ngIf="!item.is_storno">
            {{ item.trips_mileage_sum }} KM
          </td>
          <td (click)="openInvoiceDialog(item)" *ngIf="item.is_storno">-</td>

          <td (click)="openInvoiceDialog(item)">
            {{ item.total_price_euro_formatted }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            <i class="pi pi-check" *ngIf="item.status === 'FINISHED'"></i>
            <div *ngIf="item.status === 'OPENED'">
              {{ item.part_payments_sum_euro_formatted }}
            </div>
          </td>
          <td (click)="openInvoiceDialog(item)">
            <div class="text-center-container">
              {{ item.general_date | date: "dd.MM.y, H:mm" }}
              <i
                *ngIf="item.sent_type === 'email'"
                pTooltip="E-Mail"
                class="pi pi-envelope color-gray"
                style="font-size: 13px"
              ></i>
              <i
                *ngIf="item.sent_type === 'post'"
                pTooltip="Post"
                class="pi pi-file color-gray"
                style="font-size: 13px"
              ></i>
              <i
                *ngIf="item.sent_type === 'no_sending'"
                pTooltip="Kein Versand"
                class="pi pi-times color-gray"
                style="font-size: 13px"
              ></i>
            </div>
          </td>
          <td class="table-action">
            <a
              *ngIf="item.media[0]"
              [href]="
                documentService.getDocumentDownloadLink(item.media[0].uuid)
              "
              target="_blank"
            >
              <i
                tooltipPosition="left"
                pTooltip="Herunterladen"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
