<form #form="ngForm" class="dialog-form">
  <div
    class="dialog-sidebar"
    *ngIf="values.document_type === 'dlv-anschreiben' && !isEdit"
  >
    <div class="p-field">
      <label for="text">Schlusstext</label>
      <textarea
        id="text"
        name="text"
        [disabled]="!values.receiver"
        [(ngModel)]="values.text_end_dlv_anschreiben"
        placeholder="Bitte Empfänger auswählen"
        pInputTextarea
        [rows]="8"
      ></textarea>
    </div>
  </div>

  <div class="dialog-form-body">
    <div
      class="detail-information-banner"
      *ngIf="
        values.document_type === 'abtretung' && values.upload_type === 'upload'
      "
    >
      <span>Wird an opta data versendet!</span>
    </div>

    <div
      class="p-field"
      *ngIf="
        !isEdit &&
        data.type !== 'car' &&
        data.type !== 'car_damage' &&
        data.type !== 'todo' &&
        data.type !== 'user_system'
      "
    >
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Generieren"
            name="upload_type"
            [(ngModel)]="values.upload_type"
            (onClick)="buildOptions()"
            value="generate"
          ></p-radioButton>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            label="Hochladen"
            name="upload_type"
            [(ngModel)]="values.upload_type"
            (onClick)="buildOptions()"
            value="upload"
          ></p-radioButton>
        </div>
      </div>
    </div>

    <div
      class="p-field"
      *ngIf="!isEdit && data.type === 'patients' && patientOptions.length > 1"
    >
      <label for="patient">Patient *</label>
      <p-dropdown
        inputId="patient"
        name="patient"
        placeholder="Bitte auswählen"
        [options]="patientOptions"
        [(ngModel)]="data.type_id"
        optionLabel="full_name"
        optionValue="id"
        [required]="true"
        #patient="ngModel"
        [class.p-invalid]="
          patient.invalid && (patient.dirty || patient.touched)
        "
      ></p-dropdown>
    </div>

    <div class="p-field" *ngIf="!isEdit && data.type !== 'car_damage' && data.type !== 'todo'">
      <label for="document_type">Typ *</label>
      <p-dropdown
        [filter]="data.type !== 'car'"
        inputId="document_type"
        name="document_type"
        placeholder="Bitte auswählen"
        (onChange)="documentTypeSelected($event)"
        [options]="options"
        [(ngModel)]="values.document_type"
        [required]="true"
        scrollHeight="360px"
        appendTo="body"
        #document_type="ngModel"
      ></p-dropdown>
      <div *ngIf="canNotUploadMessage" class="p-invalid">
        <small>{{ canNotUploadMessage }}</small>
      </div>
    </div>

    <!-- Willkommensschreiben und DLV Anschreiben -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        !isEdit &&
        (values.document_type === 'willkommensschreiben' ||
          values.document_type === 'dlv-anschreiben')
      "
    >
      <div class="p-field p-col-12">
        <label for="receiver">Empfänger *</label>
        <p-dropdown
          inputId="receiver"
          name="receiver"
          (onChange)="receiverChanged($event)"
          [options]="receiverOptions"
          [(ngModel)]="values.receiver"
          [required]="true"
          appendTo="body"
          #receiver="ngModel"
          [class.p-invalid]="
            receiver.invalid && (receiver.dirty || receiver.touched)
          "
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field" *ngIf="isEdit">
      <label>{{ data.document_type }}</label>
    </div>

    <!-- Preisänderung 2022 -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      style="padding: 0"
      *ngIf="values.document_type === 'preisänderung-2022'"
    >
      <div class="p-field p-col-12" style="margin: 0">
        <label>Erhalten per</label>
      </div>
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Mail"
              name="upload_type"
              value="Mail"
              [(ngModel)]="values.price_change2022_type"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Post"
              name="upload_type"
              value="Post"
              [(ngModel)]="values.price_change2022_type"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Persönlich"
              name="upload_type"
              value="Persönlich"
              [(ngModel)]="values.price_change2022_type"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Betreuungskraft"
              name="upload_type"
              value="Betreuungskraft"
              [(ngModel)]="values.price_change2022_type"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-field"
      *ngIf="values.price_change2022_type === 'Betreuungskraft'"
    >
      <label for="price_change2022_caregiver">Betreuungskraft *</label>
      <p-dropdown
        [filter]="true"
        inputId="price_change2022_caregiver"
        name="price_change2022_caregiver"
        placeholder="Bitte auswählen"
        [options]="caregiverOptions"
        [(ngModel)]="values.price_change2022_caregiver_id"
        [required]="true"
        scrollHeight="360px"
        appendTo="body"
        optionValue="id"
        optionLabel="last_first_name"
        #price_change2022_caregiver="ngModel"
        [class.p-invalid]="
          price_change2022_caregiver.invalid &&
          (price_change2022_caregiver.dirty ||
            price_change2022_caregiver.touched)
        "
      ></p-dropdown>
    </div>

    <!-- PDF Auswahl -->
    <!--    <div-->
    <!--      class="p-fluid p-formgrid p-grid without-border"-->
    <!--      *ngIf="-->
    <!--        !isEdit &&-->
    <!--        values.upload_type === 'generate' &&-->
    <!--        (values.document_type === 'kundeninformation' ||-->
    <!--          values.document_type === 'abtretung' ||-->
    <!--          values.document_type === 'entbindung')-->
    <!--      "-->
    <!--    >-->
    <!--      <div class="p-field p-col-7">-->
    <!--        <div class="p-formgroup-inline">-->
    <!--          <div class="p-field-checkbox">-->
    <!--            <p-checkbox-->
    <!--              label="Als PDF generieren"-->
    <!--              name="as_pdf"-->
    <!--              [(ngModel)]="values.as_pdf"-->
    <!--            ></p-checkbox>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- Leistungsnachweis -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="!isEdit && values.document_type === 'leistungsnachweis'"
    >
      <div class="p-field p-col-7">
        <label for="month">Monat</label>
        <p-dropdown
          inputId="month"
          name="month"
          appendTo="body"
          scrollHeight="500px"
          [options]="monthOptions"
          [(ngModel)]="values.month"
          (onChange)="loadBudgetTypes()"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-5">
        <label for="year">Jahr</label>
        <p-dropdown
          inputId="year"
          name="year"
          [options]="yearOptions"
          [(ngModel)]="values.year"
          (onChange)="loadBudgetTypes()"
        ></p-dropdown>
      </div>
    </div>

    <!-- Leistungsnachweis -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="
        !isEdit &&
        values.document_type === 'leistungsnachweis' &&
        values.upload_type === 'generate'
      "
    >
      <div class="p-field p-col-12">
        <label
          >Generieren für folgende Funktionen
          <i
            class="pi pi-info-circle"
            pTooltip="Mehrfachauswahl erstellt mehrere Dokumente. Wählen Sie nichts aus, um einen Blanko zu erstellen."
          ></i
        ></label>

        <div class="p-formgroup-inline" style="margin: 20px 0 0 0">
          <div class="p-field-checkbox" *ngFor="let type of budgetTypes">
            <p-checkbox
              [label]="type"
              [value]="type"
              name="budget_type"
              [(ngModel)]="values.budget_types"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-field"
      *ngIf="
        values.document_type !== 'leistungsnachweis' ||
        values.upload_type === 'upload'
      "
    >
      <label for="name">Name *</label>
      <input
        id="name"
        name="name"
        type="text"
        [(ngModel)]="values.name"
        pInputText
        [required]="true"
        #name="ngModel"
      />
      <div
        *ngIf="name.invalid && (name.dirty || name.touched)"
        class="p-invalid"
      >
        <div *ngIf="name.errors?.required">
          <small>Bitte geben Sie einen Namen ein</small>
        </div>
      </div>
    </div>

    <div class="p-field" *ngIf="!isEdit && values.upload_type === 'upload'">
      <label for="file">Datei auswählen *</label>
      <input
        id="file"
        name="file"
        type="file"
        (change)="setSelectedFile($event)"
        pInputText
        [(ngModel)]="values.selected_file"
        [required]="true"
        #uploadFile="ngModel"
        [class.p-invalid]="
          uploadFile.invalid && (uploadFile.dirty || uploadFile.touched)
        "
      />
    </div>

    <div class="p-field" *ngIf="data.type !== 'car_damage' && showValidField()">
      <label for="valid_until">Gültig bis</label>
      <div class="p-field-checkbox checkbox-with-calendar">
        <div>
          <p-calendar
            [firstDayOfWeek]="1"
            [showWeek]="true"
            [disabled]="values.valid_unlimited"
            inputId="valid_until"
            name="valid_until"
            dateFormat="dd.mm.yy"
            appendTo="body"
            [(ngModel)]="values.valid_until"
            dataType="string"
            [required]="!values.valid_unlimited"
            #valid_until="ngModel"
            [showButtonBar]="true"
            [class.p-invalid]="
              valid_until.invalid && (valid_until.dirty || valid_until.touched)
            "
          ></p-calendar>
          <div
            *ngIf="
              valid_until.invalid && (valid_until.dirty || valid_until.touched)
            "
            class="p-invalid"
          >
            <div *ngIf="valid_until.errors?.required">
              <small>Bitte geben Sie ein Datum ein</small>
            </div>
          </div>
        </div>

        <p-checkbox
          name="important"
          [binary]="true"
          label="Unbefristet"
          [(ngModel)]="values.valid_unlimited"
        ></p-checkbox>

        <i></i>
      </div>
    </div>

    <div class="p-field" *ngIf="canBeApproved">
      <div class="p-field-checkbox">
        <p-checkbox
          name="approved_for_user"
          [binary]="true"
          label="Für den Benutzer freigeben"
          [(ngModel)]="values.approved_for_user"
        ></p-checkbox>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!isEdit || !isDeletable"></i>

    <div style="display: flex; gap: 20px">
      <i
        class="pi"
        *ngIf="isEdit && isDeletable"
        [class.pi-trash]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Löschen'"
        (click)="remove($event)"
      ></i>

      <i
        class="pi"
        *ngIf="isEdit && !data.document.is_archived"
        [class.pi-clock]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird archiviert' : 'Archivieren'"
        (click)="archive($event)"
      ></i>

      <i
        class="pi"
        *ngIf="isEdit && data.document.is_archived"
        [class.pi-clock]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird wiederhergestellt' : 'Wiederherstellen'"
        (click)="unarchive($event)"
      ></i>
    </div>

    <button
      *ngIf="!isEdit && values.upload_type === 'generate'"
      (click)="generate()"
      pButton
      label="Generieren"
      type="button"
      [disabled]="submitted || !canUpload"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'"
    ></button>

    <button
      *ngIf="!isEdit && values.upload_type === 'upload'"
      (click)="upload()"
      pButton
      label="Hochladen"
      type="button"
      [disabled]="submitted || !canUpload"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-upload'"
    ></button>

    <button
      *ngIf="isEdit"
      (click)="edit()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted || !canUpload"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
