<app-loader [loading]="loading"></app-loader>

<div
  class="content new-content-container"
  style="margin-bottom: 0"
  *ngIf="!loading"
>
  <header class="content-header">
    <span style="display: flex; gap: 5px">
      <p-multiSelect
        [style]="{ width: '240px' }"
        selectedItemsLabel="Abrechnungsstellen: {0} / {{
          accountingTypes.length
        }}"
        [maxSelectedLabels]="0"
        inputId="accounting_type"
        placeholder="Abrechnungsstelle"
        [options]="accountingTypes"
        (onChange)="loadData(false)"
        [filter]="false"
        [showHeader]="false"
        scrollHeight="500px"
        appendTo="body"
        name="accounting_type"
        [(ngModel)]="filters.accounting_type"
      ></p-multiSelect>

      <p-dropdown
        [style]="{ width: '240px' }"
        (onChange)="loadData(false)"
        [showClear]="true"
        name="invoice_receiver"
        inputId="invoice_receiver"
        [(ngModel)]="filters.invoice_receiver"
        placeholder="Rechnungsempfänger"
        [options]="invoiceReceiverTypes"
        scrollHeight="300px"
        appendTo="body"
      ></p-dropdown>

      <p-dropdown
        inputId="month"
        name="month"
        placeholder="Monat"
        [showClear]="true"
        scrollHeight="500px"
        (onChange)="loadData(false)"
        [options]="monthOptions"
        [(ngModel)]="filters.month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        placeholder="Jahr"
        [showClear]="true"
        (onChange)="loadData(false)"
        [options]="yearOptions"
        [(ngModel)]="filters.year"
        [style]="{ width: '120px', margin: '0 0 0 -1px' }"
      ></p-dropdown>
    </span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="data.invoices.length == 0"
  >
    <span class="content-no-message"
      >Keine abgeschlossenen Rechnungen vorhanden</span
    >
  </div>

  <div
    class="content-body content-body-full-height-with-subtabs without-padding"
    [class.content-loading]="contentLoading"
    *ngIf="data.invoices.length > 0"
  >
    <p-table
      #dt
      [value]="data.invoices"
      [globalFilterFields]="[
        'invoiceable.patient.id',
        'invoiceable.patient.full_name',
        'invoice_number_complete',
        'invoiceable.month',
        'invoiceable.budget_type'
      ]"
      scrollHeight="100%"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Rechnungen"
      [scrollable]="true"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="invoices-appointments-finished"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 150px" pSortableColumn="invoice_number">
            Re-Nr
            <p-sortIcon field="invoice_number"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.patient.last_name">
            Patient
            <p-sortIcon field="invoiceable.patient.last_name"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="invoiceable.month_sort">
            Leistungsmonat
            <p-sortIcon field="invoiceable.month_sort"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="invoiceable.budget_type">
            Leistung
            <p-sortIcon field="invoiceable.budget_type"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.accounting_type">
            Abrechnungsstelle
            <p-sortIcon field="invoiceable.accounting_type"></p-sortIcon>
          </th>
          <th pSortableColumn="invoiceable.invoice_receiver">
            Rechnungsempfänger
            <p-sortIcon field="invoiceable.invoice_receiver"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="total_price">
            Gesamt
            <p-sortIcon field="total_price"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="opened_at">
            Datum
            <p-sortIcon field="opened_at"></p-sortIcon>
          </th>
          <th pSortableColumn="finished_at">
            Abgeschlossen am
            <p-sortIcon field="finished_at"></p-sortIcon>
          </th>
          <th style="max-width: 50px"></th>
        </tr>
        <tr *ngIf="authService.isSuperAdmin()">
          <th style="max-width: 150px"></th>
          <th></th>
          <th style="max-width: 150px"></th>
          <th style="max-width: 150px"></th>
          <th></th>
          <th></th>
          <th style="max-width: 100px">{{ data.total.total_sum }}</th>
          <th style="min-width: 200px"></th>
          <th></th>
          <th style="max-width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td style="max-width: 150px" (click)="openInvoiceDialog(item)">
            {{ item.invoice_number_complete }}
          </td>
          <td (click)="openInvoiceDialog(item)">
            <a
              routerLink="/patients/{{
                item.invoiceable.patient.id
              }}/accounting"
              >{{ item.invoiceable.patient.last_name }},
              {{ item.invoiceable.patient.first_name }}</a
            >
          </td>
          <td style="max-width: 150px" (click)="openInvoiceDialog(item)">
            {{ item.invoiceable?.month }} {{ item.invoiceable?.year }}
          </td>
          <td style="max-width: 150px" (click)="openInvoiceDialog(item)">
            <div class="text-nowrap">
              {{ item.invoiceable.budget_type_formatted }}
            </div>
          </td>
          <td class="text-nowrap" (click)="openInvoiceDialog(item)">
            {{ item.invoiceable.accounting_type_string }}
          </td>
          <td class="text-nowrap" (click)="openInvoiceDialog(item)">
            {{ item.invoiceable.invoice_receiver_string }}
          </td>
          <td style="max-width: 100px" (click)="openInvoiceDialog(item)">
            {{ item.total_price_euro_formatted }}
          </td>
          <td style="min-width: 200px" (click)="openInvoiceDialog(item)">
            <div class="text-center-container">
              {{ item.opened_at | date: "dd.MM.y, H:mm" }}
              <span class="color-gray" *ngIf="item.user?.user_system" [pTooltip]="item.user.user_system.full_name">
                {{ item.user.user_system.first_name[0] }}{{ item.user.user_system.last_name[0] }}
              </span>
              <i
                *ngIf="item.invoiceable.send_type === 'email'"
                pTooltip="E-Mail"
                class="pi pi-envelope color-gray"
                style="font-size: 13px"
              ></i>
              <i
                *ngIf="item.invoiceable.send_type === 'post'"
                pTooltip="Post"
                class="pi pi-file color-gray"
                style="font-size: 13px"
              ></i>
              <i
                *ngIf="item.invoiceable.send_type === 'no_sending'"
                pTooltip="Kein Versand"
                class="pi pi-times color-gray"
                style="font-size: 13px"
              ></i>
            </div>
          </td>
          <td (click)="openInvoiceDialog(item)">
            {{ item.finished_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td class="table-action" style="max-width: 50px">
            <a
              *ngIf="item.media[0]"
              [href]="
                documentService.getDocumentDownloadLink(item.media[0].uuid)
              "
              target="_blank"
            >
              <i
                tooltipPosition="left"
                pTooltip="Herunterladen"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
