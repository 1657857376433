<form
  #form="ngForm"
  class="dialog-form"
  style="height: 100%; display: flex; flex-direction: column"
>
  <div class="dialog-loader white-background" *ngIf="loading">
    <i class="loading-logo"></i>
    WIRD GELADEN
  </div>

  <div class="dialog-form-footer on-top" *ngIf="!loading">
    <i *ngIf="data.is_storno"></i>
    <div class="dialog-footer-action-container" *ngIf="!data.is_storno">
      <button
        pButton
        label="Stornieren"
        class="p-button-danger"
        type="button"
        (click)="stornoInvoice()"
      ></button>

      <button
        *ngIf="data.status === 'OPENED' && authService.can('Abrechnung.Mahnungen versenden') && nextReminderType"
        pButton
        label="{{ nextReminderType }}. Mahnung erstellen"
        class="p-button-warning"
        type="button"
        (click)="sendInvoiceReminder()"
      ></button>
    </div>


    <div class="dialog-footer-action-container">
      <a
        *ngIf="
          data.media[0] &&
          ['OPENED', 'FINISHED'].includes(data.status) &&
          data.type === 'CONSULTING'
        "
        target="_blank"
        [href]="
          documentService.getDocumentDownloadLink(
            data.invoiceable.media[0].uuid
          )
        "
      >
        <button
          pButton
          label="Nachweis herunterladen"
          type="button"
          icon="pi pi-file-pdf"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a target="_blank" [href]="pdfSrc">
        <button
          pButton
          label="Rechnung herunterladen"
          type="button"
          icon="pi pi-file-pdf"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <button
        *ngIf="data.status === 'OPENED' && !data.is_storno && authService.isSuperAdmin()"
        (click)="markInvoiceAsCompleted()"
        pButton
        label="Vollständig bezahlt"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="data.status === 'FINISHED' && !data.is_storno && authService.isSuperAdmin()"
        (click)="resetCompletedStatus()"
        pButton
        class="p-button-danger"
        label="Auf 'Offen' zurücksetzen"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-times'"
      ></button>
    </div>
  </div>

  <div
    class="dialog-form-body without-padding"
    style="height: calc(100% - 54px)"
    *ngIf="!loading"
  >
    <div class="proof-check-container">
      <!-- Links -->
      <div class="proof-check-image-container" style="margin: 0">
        <div [class.is-rotating]="rotating" class="proof-check-image-item">
          <embed
            [src]="pdfSrcSafe"
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      </div>

      <!-- Rechts -->
      <div class="proof-check-actions-container row-view">
        <!-- DATEN -->
        <div class="sidebar-inline-calendar" style="flex-direction: column">
          <div
            class="p-fluid p-formgrid p-grid without-border"
            style="width: 100%; padding: 20px"
          >
            <div class="p-field p-col-4">
              <label>Patient</label>
              <a
                target="_blank"
                style="color: currentColor"
                routerLink="/patients/{{ patient.id }}"
              >
                <strong>{{ patient.first_name }} {{ patient.last_name }}</strong
                ><i
                style="font-size: 12px; margin: 0 0 0 5px"
                class="color-gray pi pi-arrow-up-right"
              ></i>
              </a>
            </div>

            <div class="p-field p-col-4">
              <label>Rechnungsnummer</label>
              <strong>{{ data.invoice_number_complete }}</strong>
            </div>

            <div class="p-field p-col-4">
              <label>Versand an</label>
              <strong>
                {{ data.invoice_receiver_text }}
                <i
                  class="pi pi-info-circle"
                  tooltipStyleClass="tooltip-wider"
                  *ngIf="
                    data.invoice_receiver === 'other' &&
                    data.sent_type === 'email'
                  "
                  [pTooltip]="data.individual_email"
                ></i>
                <i
                  class="pi pi-info-circle"
                  tooltipStyleClass="tooltip-wider"
                  *ngIf="
                    data.invoice_receiver === 'other' &&
                    data.sent_type === 'post'
                  "
                  [pTooltip]="data.address"
                ></i>
              </strong>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid without-border"
            style="width: 100%; padding: 20px"
          >
            <div class="p-field p-col-4" *ngIf="data.type === 'CONSULTING'">
              <label>BE durch</label>
              <strong>
                {{ data.invoiceable.for_user }}
              </strong>
            </div>

            <!-- Wie wurde die Rechnung versendet -->
            <div class="p-field p-col-4">
              <label>Versand per</label>
              <strong>{{ data.sent_type_text }}</strong>
            </div>

            <div class="p-field p-col-4" *ngIf="data.status === 'OPENED'">
              <label>Versand / Ausdruck am</label>
              <strong>{{ data.opened_at | date: "dd.MM.y, H:mm" }} Uhr</strong>
            </div>
            <div class="p-field p-col-4" *ngIf="data.status === 'FINISHED'">
              <label>Abgeschlossen am</label>
              <strong
                >{{ data.finished_at | date: "dd.MM.y, H:mm" }} Uhr</strong
              >
            </div>
          </div>

          <div
            *ngIf="allReminders.length > 0"
            class="p-fluid p-formgrid p-grid without-border"
            style="width: 100%; padding: 20px"
          >
            <div class="p-field p-col-12">
              <label>Mahnungen</label>
              <div style="display: flex; justify-content: space-between">
                <div *ngFor="let reminder of allReminders" style="display: flex; flex-direction: column">
                  <a
                    target="_blank"
                    style="color: currentColor"
                    [routerLink]="reminder.media.length > 0 ? documentService.getDocumentDownloadLink(reminder.media[0].uuid) : invoiceService.getReminderMailLink(reminder.id)"
                  >
                    <strong>{{ reminder.type }}. Mahnung</strong>
                    <i
                      style="font-size: 12px; margin: 0 0 0 5px"
                      class="color-gray pi pi-arrow-up-right"
                    ></i>
                  </a>
                  <small>{{ reminder.sent_type_string }}, {{ reminder.created_at | date: "dd.MM.y, H:mm" }} von {{ reminder.sent_history.created_from_name }}</small>
                </div>
              </div>
            </div>
          </div>

          <!--           KM Rechnung Tabelle -->
          <div class="" *ngIf="data.type === 'KM'">
            <p-table
              #dt
              [value]="data.trips"
              [rowHover]="true"
              [scrollable]="true"
              scrollHeight="320px"
              [autoLayout]="true"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Datum</th>
                  <th>Betreuungskraft</th>
                  <th>Kilometer</th>
                  <th>Summe</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr [attr.data-id]="item.id">
                  <td>
                    {{ item.persplan_budget.real_from | date: "dd.MM.y" }} ({{
                      item.persplan_budget.weekday[0]
                    }}{{ item.persplan_budget.weekday[1] }})
                  </td>
                  <td>{{ item.persplan_budget.caregiver.full_name }}</td>
                  <td>{{ item.mileage }} KM</td>
                  <td>
                    {{ item.mileage_price_euro_formatted }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <div class="sidebar-inline-calendar part-payment-container">
          <label style="display: flex; align-items: center; gap: 10px">
            Teilzahlung

            <span class="color-gray" style="font-size: 13px">
              {{ data.part_payments_sum_euro_formatted }} /
              {{ data.total_price_euro_formatted }}
            </span>
          </label>

          <!-- Buttons -->
          <div
            class="p-fluid p-formgrid p-grid without-border"
            *ngIf="data.status === 'OPENED' && !data.is_storno"
          >
            <div class="p-field p-col-6">
              <div class="p-inputgroup" style="width: 130px">
                <p-inputNumber
                  mode="decimal"
                  locale="de-DE"
                  [minFractionDigits]="2"
                  [min]="0"
                  [maxFractionDigits]="2"
                  inputId="partialPayment"
                  name="partialPayment"
                  [(ngModel)]="partialPayment"
                ></p-inputNumber>
                <span class="p-inputgroup-addon">€</span>
              </div>
            </div>
            <div class="p-field p-col-6">
              <button
                (click)="addPayment()"
                pButton
                [label]="partialPaymentEditId ? 'Bearbeiten' : 'Eintragen'"
                type="button"
                [disabled]="submitted"
                class="p-button p-button-outlined p-button-text"
                [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              ></button>
            </div>
          </div>

          <!-- Inhalte -->
          <div
            class="part-payment-container-inner"
            [class.with-border]="data.status === 'OPENED' && !data.is_storno"
          >
            <!-- Keine Daten vorhanden -->
            <div
              class="dialog-no-message dialog-no-comment color-gray"
              *ngIf="data.part_payments.length === 0"
            >
              Keine Teilzahlungen vorhanden
            </div>

            <!-- Daten als Liste -->
            <div
              class="dialog-sidebar-part-payment"
              *ngFor="let payment of data.part_payments"
            >
              <i
                tooltipPosition="left"
                (click)="editPayment(payment)"
                *ngIf="data.status === 'OPENED'"
                class="pi pi-pencil"
                pTooltip="Bearbeiten"
              ></i>
              <strong>{{ payment.payment_euro_formatted }}</strong>
              <span
                >{{ payment.user.name }} am
                {{ payment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer" *ngIf="!loading">
    <i *ngIf="data.is_storno"></i>
    <div class="dialog-footer-action-container" *ngIf="!data.is_storno">
      <button
        pButton
        label="Stornieren"
        class="p-button-danger"
        type="button"
        (click)="stornoInvoice()"
      ></button>

      <button
        *ngIf="data.status === 'OPENED' && authService.can('Abrechnung.Mahnungen versenden') && nextReminderType"
        pButton
        label="{{ nextReminderType }}. Mahnung erstellen"
        class="p-button-warning"
        type="button"
        (click)="sendInvoiceReminder()"
      ></button>
    </div>

    <div class="dialog-footer-action-container">
      <a
        *ngIf="
          data.media[0] &&
          ['OPENED', 'FINISHED'].includes(data.status) &&
          data.type === 'CONSULTING'
        "
        target="_blank"
        [href]="
          documentService.getDocumentDownloadLink(
            data.invoiceable.media[0].uuid
          )
        "
      >
        <button
          pButton
          label="Nachweis herunterladen"
          type="button"
          icon="pi pi-file-pdf"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a target="_blank" [href]="pdfSrc">
        <button
          pButton
          label="Rechnung herunterladen"
          type="button"
          icon="pi pi-file-pdf"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <button
        *ngIf="data.status === 'OPENED' && !data.is_storno && authService.isSuperAdmin()"
        (click)="markInvoiceAsCompleted()"
        pButton
        label="Vollständig bezahlt"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="data.status === 'FINISHED' && !data.is_storno && authService.isSuperAdmin()"
        (click)="resetCompletedStatus()"
        pButton
        class="p-button-danger"
        label="Auf 'Offen' zurücksetzen"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-times'"
      ></button>
    </div>
  </div>
</form>
