<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div class="dialog-no-message" *ngIf="data.item.documents.length === 0">
          Keine Dokumente hochgeladen
        </div>
        <div class="document-list" *ngFor="let document of data.item.documents">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="dialog-detail-information-body">
      <div
        class="dialog-detail-information dialog-detail-information-has-actions"
      >
        <div class="dialog-detail-key p-align-center">
          <span style="margin: 0">Status</span>
        </div>
        <div class="dialog-detail-value selectbutton-small">
          <p-selectButton
            [options]="statusOptions"
            [(ngModel)]="values.status"
            name="status"
            (onChange)="changeStatus($event)"
          >
            <ng-template let-item>
              <span [style]="{ backgroundColor: item.backgroundColor }">{{
                item.label
              }}</span>
            </ng-template>
          </p-selectButton>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Erstellt am</span></div>
        <div class="dialog-detail-value">
          <div>{{ data.item.created_at | date: "dd.MM.y, H:mm" }} Uhr</div>
        </div>
      </div>
      <div
        class="dialog-detail-information"
        *ngIf="data.item.process_status === 'EDITED'"
      >
        <div class="dialog-detail-key"><span>In Bearbeitung seit</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ data.item.edited_history.created_at | date: "dd.MM.y, H:mm" }}
            Uhr von
            {{ data.item.edited_history.created_from_name }}
          </div>
        </div>
      </div>
      <div
        class="dialog-detail-information"
        *ngIf="data.item.process_status === 'FINISHED'"
      >
        <div class="dialog-detail-key"><span>Erledigt am</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ data.item.finished_history.created_at | date: "dd.MM.y, H:mm" }}
            Uhr von
            {{ data.item.finished_history.created_from_name }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Patient</span></div>
        <div class="dialog-detail-value">
          <div>
            <strong
              ><a
                href="/patients/{{ data.item.data.patient.id }}"
                target="_blank"
                >{{ data.item.data.patient.full_name }}</a
              ></strong
            >
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Betreuungskraft</span></div>
        <div class="dialog-detail-value">
          <div>
            <a
              href="/caregivers/{{ data.item.data.caregiver.id }}"
              target="_blank"
              >{{ data.item.data.caregiver.full_name }}</a
            >
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Einsatz</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ data.item.data.real_from | date: "dd.MM.y" }} ({{
              data.item.data.weekday[0]
            }}{{ data.item.data.weekday[1] }}),
            {{ data.item.data.real_from_h }} -
            {{ data.item.data.real_to_h }} Uhr
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Details</span></div>
        <div class="dialog-detail-value">
          <div
            style="white-space: pre-wrap"
            *ngIf="data.item.type === 'FEEDBACK'"
          >
            <div>{{ data.item.tooltip }}</div>
          </div>

          <div style="white-space: pre-wrap" *ngIf="data.item.type === 'TRIP'">
            <div>
              {{
                data.item.data.caregiver_appointment_trip.mileage
                  .toString()
                  .replace(".", ",")
              }}
              KM <br /><br />{{ data.item.tooltip }}
            </div>
          </div>

          <div
            style="white-space: pre-wrap"
            *ngIf="data.item.type === 'TIME_CHANGE'"
          >
            <div>
              <span
                *ngIf="data.item.data.caregiver_appointment_change.time > 0"
              >
                +</span
              >{{
                data.item.data.caregiver_appointment_change.time
                  .toString()
                  .replace(".", ",")
              }}
              Std
              <span
                *ngIf="
                  data.item.data.caregiver_appointment_change.time_feedback
                "
                ><br /><br />{{
                  data.item.data.caregiver_appointment_change.time_feedback
                }}</span
              >
            </div>
          </div>

          <div
            style="white-space: pre-wrap"
            *ngIf="data.item.type === 'DRIVE_TIME_FEEDBACK'"
          >
            <div>
              <span
                *ngIf="
                  data.item.data.caregiver_appointment_change.drive_time > 0
                "
              >
                +</span
              >{{
                data.item.data.caregiver_appointment_change.drive_time
                  .toString()
                  .replace(".", ",")
              }}
              min
              <span
                *ngIf="
                  data.item.data.caregiver_appointment_change
                    .drive_time_feedback
                "
                ><br /><br />{{
                  data.item.data.caregiver_appointment_change
                    .drive_time_feedback
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="dialog-detail-information"
        *ngIf="
          data.item.type === 'DRIVE_TIME_FEEDBACK' &&
          (finishedSelected || values.status === 'FINISHED')
        "
      >
        <div class="dialog-detail-key"><span>Auswertung</span></div>
        <div class="dialog-detail-value" style="flex-direction: column">
          <div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-5">
                <p-inputNumber
                  inputId="mileage"
                  name="mileage"
                  locale="de-DE"
                  buttonLayout="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  [showButtons]="true"
                  [(ngModel)]="
                    data.item.data.caregiver_appointment_change.drive_time
                  "
                ></p-inputNumber>
              </div>
            </div>
          </div>
          <div>
            <br />

            <p-checkbox
              name="credit-time"
              [binary]="true"
              (onChange)="creditDriveTimeChanged($event)"
              label="Fahrzeit gutschreiben"
              [(ngModel)]="
                data.item.data.caregiver_appointment_change.credit_drive_time
              "
            ></p-checkbox>

            <br />
            <br />

            <p-checkbox
              name="send_drive_time_mail"
              [binary]="true"
              (onChange)="sendDriveTimeMailChanged($event)"
              label="Gutschrift per Mail versenden"
              [(ngModel)]="
                data.item.data.caregiver_appointment_change.send_drive_time_mail
              "
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-body without-padding" *ngIf="!finishedSelected">
    <div class="dialog-comments">
      <div
        class="dialog-comment dialog-no-comment"
        *ngIf="comments.length === 0"
      >
        Noch keine Kommentare
      </div>
      <div class="dialog-comment" *ngFor="let comment of comments">
        <!-- Den Kommentar darf nur der Ersteller bearbeiten bzw. ein Super Admin -->
        <i
          *ngIf="
            currentUser?.id === comment.user_id || authService.isSuperAdmin
          "
          class="pi pi-pencil"
          pTooltip="Bearbeiten"
          (click)="openEditMode(comment)"
        ></i>
        <strong>{{ comment.comment }}</strong>
        <span
          >{{ comment.created_from.name }} am
          {{ comment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >
      </div>
    </div>
  </div>
  <div class="dialog-form-footer dialog-form-footer-textarea">
    <textarea
      *ngIf="finishedSelected"
      id="comment"
      name="comment"
      [(ngModel)]="values.comment"
      [rows]="2"
      placeholder="Kommentar für History bei Patient und Betreuungskraft..."
      pInputTextarea
    ></textarea>

    <textarea
      *ngIf="!editComment && !finishedSelected"
      id="comment"
      name="comment"
      [(ngModel)]="values.comment"
      [rows]="2"
      placeholder="Kommentar..."
      [autofocus]="true"
      pInputTextarea
    ></textarea>

    <textarea
      *ngIf="editComment && !finishedSelected"
      id="comment_edit"
      name="comment_edit"
      [(ngModel)]="values.comment"
      [rows]="2"
      pInputTextarea
    ></textarea>

    <div class="dialog-form-footer-actions">
      <span class="color-main1" *ngIf="todoCreated">Todo wurde erstellt</span>
      <button
        *ngIf="
          !editComment && !todoCreated && !data.item.todo && !finishedSelected
        "
        pButton
        label="Todo erstellen"
        class="p-button-outlined"
        type="button"
        (click)="openTodoDialog()"
        icon="pi pi-check-circle"
        tabindex="-1"
      ></button>
      <button
        *ngIf="
          !editComment && !todoCreated && data.item.todo && !finishedSelected
        "
        pButton
        label="Todo ansehen"
        class="p-button-outlined"
        type="button"
        (click)="openShowTodoDialog()"
        icon="pi pi-eye"
      ></button>

      <button
        *ngIf="editComment && !finishedSelected"
        pButton
        label="Abbrechen"
        class="p-button-text button-without-padding"
        type="button"
        (click)="cancelEditMode()"
        icon="pi pi-times"
      ></button>

      <button
        *ngIf="!editComment && !finishedSelected"
        (click)="save()"
        pButton
        label="Kommentar erstellen"
        type="button"
        [disabled]="submitted || !values.comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="editComment && !finishedSelected"
        (click)="update()"
        pButton
        label="Kommentar bearbeiten"
        type="button"
        [disabled]="submitted || !values.comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <div
        *ngIf="finishedSelected"
        style="
          display: flex;
          align-items: flex-end;
          width: 100%;
          justify-content: space-between;
        "
      >
        <button
          pButton
          label="Status ohne Kommentar und History speichern"
          class="p-button-outlined"
          type="button"
          (click)="saveStatus(true)"
          icon="pi pi-check"
        ></button>

        <div>
          <div class="p-field-checkbox">
            <p-checkbox
              [disabled]="submitted || !values.comment"
              name="important"
              [(ngModel)]="values.important"
              [binary]="true"
              label="Wichtiges Ereignis"
            ></p-checkbox>
          </div>

          <button
            (click)="saveWithHistory()"
            pButton
            label="Status mit History speichern"
            type="button"
            [disabled]="submitted || !values.comment"
            [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
